import React from "react";
import { useQuery } from "react-query";

import { Container } from "components/Container/Container";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { getSinglePage } from "api/General";
import { Triangle } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { removeHTMLEntities } from "lib/helpers/removeHTMLEntities";

import "./SinglePage.scss";

export const SinglePage = (props: any) => {
  const currentPath = window.location.pathname
  const slug = currentPath.replace('/', '');

  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";
  const { data, isLoading, isError } = useQuery(
    ["getSinglePage", slug],
    () => getSinglePage(slug)
  );

  if (isLoading) {
    return (
      <div className="SinglePage">
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 100,
            }}
          >
            <Triangle
              height="80"
              width="80"
              color="#000"
              ariaLabel="triangle-loading"
              visible
            />
          </div>
        </Container>
      </div>
    );
  }

  if (isError ) {
    return (
      <div className="SinglePage">
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 100,
            }}
          >
            <p>
              {" "}
              {isEnglish
                ? "Something went wrong, please try again!"
                : "Dicka shkoi gabim, ju lutemi provoni përsëri!"}
            </p>
          </div>
        </Container>
      </div>
    );
  }

  const pageData = data?.data ?? {};

  return (
    <>
      <div className="SinglePage">
        <div className="SinglePage__wrapper">
          {
            data?.thumbnail ? 
              <div className="SinglePage__wrapper_top">
                <div className="SinglePage__wrapper_top--img">
                  <img alt="img" src={data.thumbnail} />
                </div>
                <div className="SinglePage__wrapper_top--title">
                  <h1>{data?.title}</h1>
                </div>
              </div>
            : ''
          }
          <Container>
            <div className="SinglePage__wrapper_bottom">
              <p
                dangerouslySetInnerHTML={{
                  __html: removeHTMLEntities(pageData?.description).toString(),
                }}
              />
              <div className="SinglePage__wrapper_bottom-items">
                {data?.externalLinks &&
                  data?.externalLinks?.map(
                    (
                      item: {
                        description: string;
                        external_link: string;
                        thumbnail: string;
                        title: string;
                      },
                      index: number
                    ) => {
                      return (
                        <div
                          key={`${item.title}-${index}`}
                          className="SinglePage__wrapper_bottom-items--item"
                        >
                          <div className="SinglePage__wrapper_bottom-items--item--left">
                            <img alt="img" src={item.thumbnail} />
                          </div>
                          <div className="SinglePage__wrapper_bottom-items--item--middle">
                            <h2>{item?.title}</h2>
                            <p>{item?.description}</p>
                          </div>
                          <div
                            onClick={() =>
                              (window.location.href = item?.external_link)
                            }
                            className="SinglePage__wrapper_bottom-items--item--right"
                          >
                            <ArrowRight style={{ width: 19 }} />
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </Container>
        </div>
      </div>
        {pageData?.more_info ?  <div className="Footer__main__top">
          <Container>
            <div className="Footer__main__top__wrapper">
              <h2>{pageData?.more_info?.title}</h2>
              <p>{pageData?.more_info?.description}</p>
              <div className="Footer__main__top__wrapper_btns">
                <div className="Header--bottom--buttons">
                  <button
                    onClick={() =>
                      (window.location.href =
                        pageData?.more_info?.first_button_url)
                    }
                    className="Header--bottom--buttons"
                  >
                    {pageData?.more_info?.first_button_text}
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href =
                        pageData?.more_info?.second_button_url)
                    }
                    className="Header--bottom--buttons-rev"
                  >
                    {pageData?.more_info?.second_button_text}
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </div> : ''}
    </>
  );
};
