import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const Metadata = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const slug = location.pathname.replace("/", "");
  let title =  "Lider në shpërndarjen e teknologjisë | Profiti Group";
  let description = "Profiti Group ofron zgjidhje të avancuara teknologjike për bizneset, duke kombinuar dekada përvojë dhe inovacion për të plotësuar çdo nevojë teknologjike.";

  switch(true){
    case slug.includes('rreth-nesh'):
      title = "Meso me shume per Profitin | Profiti Group";
      description = "Learn more about us and our mission to create amazing experiences.";
      break;
    case slug.includes('kategoritë'):
      title = "Kategorite e produkteve | Profiti Group";
      description = "Ne ofrojmë gjithçka që ju nevojitet, duke kombinuar cilësinë dhe inovacionin për zgjidhje teknologjike të shkëlqyera për shtëpinë dhe biznesin tuaj.";
      break;
    case slug.includes('portfolio'):
      title = "Portfolio e Profitit | Profiti Group";
      description = "Mesoni me shume rreth Profiti Group duke e eksploruar portfolion tone! Ketu mund te shikoni me shume per eventet dhe trajnimet e Profiti Group.";
      break;
    case slug.includes('kontakti'):
      title = "Na kontaktoni | Profiti Group";
      description = "Bashkëpunoni me ne! Ekipi ynë është këtu për t'ju mbështetur dhe për t'ju ndihmuar të arrini suksesin. Na kontaktoni sot +383 49 180 091.";
      break;
    default:
      break;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`https://www.profiti-ks.com${currentPath}`} />
    </Helmet>
  );
};

export default Metadata;
