import React from "react";
import { getAllCategories, getCategoryPage } from "api/General";
import { Container } from "components/Container/Container";
import { useQuery } from "react-query";
import { useWindowWidth } from "lib/hooks/useWindowWidth";
import { useHistory } from "react-router";
import { Triangle } from "react-loader-spinner";

import "./Categories.scss";
import { ThemeContainer } from "components/ThemeContainer/ThemeContainer";
import { useTranslation } from "react-i18next";

export const Categories = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 1024;
  const history = useHistory();
  const { data, isLoading, isError } = useQuery(
    "getCategoryPage",
    getCategoryPage
  );

  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  const aboutUsData = data?.data ?? {};

  const allCategories = useQuery("getAllCategories", getAllCategories);

  const finalAllCategories = allCategories?.data ?? [];

  const assignGridPositions = () => {
    return finalAllCategories.map(
      (
        box: {
          id: number;
          content: string;
          slug: string;
          thumbnail: string;
          title: string;
          short_description: string;
          background_color: string;
          thumbnails: {
            thumbnail: string;
            medium: string;
            medium_large: string;
            large: string;
          };
        },
        index: number
      ) => {
        const lineIndex = Math.floor(index / 3);
        const isFirstLine = lineIndex % 2 === 0;
        const isBig =
          (isFirstLine && index % 3 === 0) || (!isFirstLine && index % 3 === 2);
        const gridColumn = isBig ? "span 2" : "span 1";
        const gridRow = lineIndex + 1;

        return (
          <div
            onClick={() => history.push(`kategoritë/${box.slug}`)}
            id={`SlideShow-${box.id}`}
            className="SlideShow-box"
            key={`${box.id}-${index}`}
            style={{ gridColumn, gridRow }}
          >
            <div className="SlideShow--category">
              <div className="SlideShow--category-img">
                <img alt="img" src={box?.thumbnails?.medium_large} />
              </div>

              <div
                style={{ backgroundColor: box.background_color }}
                className="SlideShow--category-desc"
              >
                <span>{box?.title}</span>
                {box?.short_description && <p>{box.short_description}</p>}
              </div>
            </div>
          </div>
        );
      }
    );
  };

  const categoryData = data ?? {};

  if (isLoading) {
    return (
      <div className="Categories">
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="Categories__wrapper"
          >
            <Triangle
              height="80"
              width="80"
              color="#000"
              ariaLabel="triangle-loading"
              visible
            />
          </div>
        </Container>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="Categories">
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="Categories__wrapper"
          >
            {isEnglish
              ? "Something went wrong, please try again!"
              : "Dicka shkoi gabim, ju lutemi provoni përsëri!"}
          </div>
        </Container>
      </div>
    );
  }

  return (
    <>
      <div className="Categories">
        <Container>
          <div className="Categories__wrapper">
            <div className="Categories__wrapper__top">
              <h2>{categoryData?.data?.title}</h2>
              <p>{categoryData?.data?.description}</p>
            </div>
            <ThemeContainer>
              <div className="Categories__wrapper__bottom">
                {!isMobile
                  ? assignGridPositions()
                  : finalAllCategories.map(
                      (
                        box: {
                          id: number;
                          content: string;
                          slug: string;
                          thumbnail: string;
                          title: string;
                          short_description: string;
                          background_color: string;
                          thumbnails: {
                            thumbnail: string;
                            medium: string;
                            medium_large: string;
                            large: string;
                          };
                        },
                        index: number
                      ) => {
                        return (
                          <div
                            id={`SlideShow-${box.id}`}
                            className="SlideShow-box"
                            key={`${box.id}-${index}`}
                            onClick={() =>
                              history.push(`kategoritë/${box.slug}`)
                            }
                          >
                            <div className="SlideShow--category">
                              <div className="SlideShow--category-img">
                                <img alt="img" src={box?.thumbnail} />
                              </div>

                              <div
                                style={{
                                  backgroundColor: box.background_color,
                                }}
                                className="SlideShow--category-desc"
                              >
                                <span>{box?.title}</span>
                                {box?.short_description && (
                                  <p>{box?.short_description}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
              </div>
            </ThemeContainer>
          </div>
        </Container>
      </div>
      {aboutUsData?.more_info?.first_button_text && (
        <div className="Footer__main__top">
          <Container>
            <div className="Footer__main__top__wrapper">
              <h2>{aboutUsData?.more_info?.title}</h2>
              <p>{aboutUsData?.more_info?.description}</p>
              <div className="Footer__main__top__wrapper_btns">
                <div className="Header--bottom--buttons">
                  <button
                    onClick={() =>
                      (window.location.href =
                        aboutUsData?.more_info?.first_button_url)
                    }
                    className="Header--bottom--buttons"
                  >
                    {aboutUsData?.more_info?.first_button_text}
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href =
                        aboutUsData?.more_info?.second_button_url)
                    }
                    className="Header--bottom--buttons-rev"
                  >
                    {aboutUsData?.more_info?.second_button_text}
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};
