// @ts-nocheck
import { RouteProps, Switch } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import { Home } from "../pages/Home/Home";
import { Page404 } from "../pages/Page404/Page404";
import { RestrictedRoute } from "./RestrictedRoute";
import { AboutUs } from "pages/AboutUs/AboutUs";
import { HowTo } from "pages/HowTo/HowTo";
import { Categories } from "pages/Categories/Categories";
import { ContactUs } from "pages/ContactUs/ContactUs";
import { SingleCategory } from "components/SingleCategory/SingleCategory";
import { SinglePage } from "components/SinglePage/SinglePage";
import { HowToSingle } from "pages/HowToSingle/HowToSingle";

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}
interface AppRoute extends RouteProps {
  type?: RouteType;
}
export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "rreth-nesh",
    component: AboutUs,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "blog",
    component: HowTo,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "blog/:slug",
    component: HowToSingle,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "kontakti",
    component: ContactUs,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "kategoritë",
    component: Categories,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "kategoritë/:id",
    component: SingleCategory,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "terms",
    component: SinglePage,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "privacy",
    component: SinglePage,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "cookies",
    component: SinglePage,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "/",
    component: Home,
  },
];

export const Routes = () => {
  return (
    <Switch>
      {AppRoutes.map((r) => {
        const { type, path, ...rest } = r;
        if (type === RouteType.PRIVATE) {
          return (
            <PrivateRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
          );
        }
        if (type === RouteType.RESTRICTED) {
          return (
            <RestrictedRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
          );
        }

        return <PublicRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />;
      })}

      <PublicRoute component={Page404} />
    </Switch>
  );
};
