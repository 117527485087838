import React from "react";
import { Container } from "components/Container/Container";

import "./Partners.scss";

interface Props {
  partners: {
    button_title: string;
    button_url: string;
    description: string;
    title: string;
    gallery: string[];
  };
}

export const Partners = (props: Props) => {
  const { partners } = props;

  return (
    <div className="Partners">
      <Container>
        <div className="Partners__wrapper">
          <div className="Partners__wrapper_left">
            <h2>{partners?.title}</h2>
            <p>{partners?.description}</p>
            <div className="Header--bottom--buttons">
              <button
                onClick={() =>
                  (window.location.href = `${partners.button_url}`)
                }
              >
                {partners?.button_title}
              </button>
            </div>
          </div>
          <div className="Partners__wrapper_right">
            {partners?.gallery?.map((item, index) => {
              return <img key={index} src={item} alt="img" />;
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};
