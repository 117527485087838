import cs from "classnames";
import { useHistory } from "react-router";
import { Container } from "components/Container/Container";
import { NavigationBar } from "components/NavigationBar/NavigationBar";
import { ReactComponent as Logoo } from "../../../assets/images/logoblack.svg";
import { useTranslation } from "react-i18next";
import { useRouter } from "lib/hooks/useRouter";
import { capitalizeString } from "lib/helpers/capitalizeString";

import "./OtherHeader.scss";

export const OtherHeader = () => {
  const router = useRouter();
  const history = useHistory();
  const { t } = useTranslation("labels");

  const pageName = router.location.pathname
    .replaceAll("/", "")
    .replaceAll("-", " ");

  return (
    <div
      className={cs(
        "OtherHeader",
        (pageName === "kontakti" ||
          router.location.pathname.includes("kategoritë/") ||
          router.location.pathname.includes("blog/")) &&
          "OtherHeader__contact"
      )}
    >
      <div className="OtherHeader--top">
        <div
          onClick={() => {
            history.push("/");
          }}
        >
          <Logoo />
        </div>
        <NavigationBar />
      </div>

      {pageName !== "kontakti" &&
        !router.location.pathname.includes("kategoritë/") &&
        !router.location.pathname.includes("blog/") && (
          <div className="OtherHeader--bottom">
            <Container>
              <h1>{t(capitalizeString(pageName))}</h1>
            </Container>
          </div>
        )}
    </div>
  );
};
